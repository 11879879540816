var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"600px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"title secondary mb-4"},[_vm._v("Create Instrument")]),_c('v-card-text',[_c('p',[_vm._v("Please enter a name for the new instrument.")]),_c('v-text-field',{attrs:{"filled":"","label":"Name","type":"text"},model:{value:(_vm.dialog.instrument.name),callback:function ($$v) {_vm.$set(_vm.dialog.instrument, "name", $$v)},expression:"dialog.instrument.name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.dialog.saving},on:{"click":function($event){return _vm.createInstrument()}}},[_vm._v(" Create ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"600px"},model:{value:(_vm.viewFilesDialog),callback:function ($$v) {_vm.viewFilesDialog=$$v},expression:"viewFilesDialog"}},[(_vm.currentItem)?_c('v-card',[_c('v-card-title',{staticClass:"title secondary mb-4"},[_vm._v(_vm._s(_vm.currentItem.Name))]),_c('v-card-text',[_vm._v(" Files: "),_c('div',{staticStyle:{"max-height":"200px","overflow":"scroll"}},[_c('strong',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(_vm.currentItem.ObjectList.split("~").join("\n")))])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.viewFilesDialog = false;
            _vm.currentItem = null;}}},[_vm._v("close")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$route.path.split("/")[1].toUpperCase())+" Instruments ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"pa-2 mt-4"},[_vm._v(" Need to create a new instrument? "),_c('v-btn',{staticClass:"ml-2",attrs:{"rounded":"","outlined":"","text":"","x-small":""},on:{"click":function($event){_vm.dialog.show = true}}},[_vm._v("Click here")])],1),_c('v-card',{attrs:{"elevation":"2","color":"secondary"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.headers[this.store],"items":_vm.instrumentsList,"search":_vm.search,"items-per-page":10,"footer-props":{
            itemsPerPageOptions: [10, 50, 100, -1],
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-1",attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Filter","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.Zip",fn:function(ref){
          var item = ref.item;
return [(!_vm.zips.length)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"color":"grey lighten--2"}}):_c('v-autocomplete',{staticClass:"mb-2",attrs:{"items":_vm.zips,"label":"Zip File","hide-details":"","autocomplete":""},on:{"change":function($event){return _vm.updateZip(item)}},model:{value:(item.Zip),callback:function ($$v) {_vm.$set(item, "Zip", $$v)},expression:"item.Zip"}})]}},{key:"item.ShopifyProductId",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":("https://" + _vm.storeUrl + "/admin/products/" + (item.ShopifyProductId)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.ShopifyProductId)+" ")])]}},{key:"item.options",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","small":"","to":((_vm.$route.path) + "/" + (item.Id))}},[_vm._v("edit")])]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }