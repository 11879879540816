var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.currentItem)?_c('v-card',[_c('v-card-title',{staticClass:"title secondary mb-4"},[_vm._v(_vm._s(_vm.currentItem.title))]),_c('v-card-text',[_c('p',[_vm._v(" Select the correct instrument below. This will add the shopify variant ID to the instrument record in the database. ")]),_vm._l((_vm.currentItem.variants),function(v){return _c('div',{key:v.id},[_c('v-autocomplete',{attrs:{"placeholder":"Instrument","items":_vm.instrumentsList,"return-object":"","item-text":"Name","item-value":"Id","hint":v.title === 'Default Title' ? '' : v.title,"persistent-hint":""},model:{value:(v.instrument),callback:function ($$v) {_vm.$set(v, "instrument", $$v)},expression:"v.instrument"}}),(v.instrument && v.instrument.ObjectList.length)?_c('div',{staticClass:"mt-3"},[_vm._v(" Files: "),_c('div',{staticStyle:{"max-height":"200px","overflow":"scroll"}},[_c('strong',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(v.instrument.ObjectList.split("~").join("\n")))])])]):_vm._e()],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false;
            _vm.currentItem = null;}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$route.path.split("/")[1].toUpperCase())+" Products ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"elevation":"2","color":"secondary"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-1",attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Filter","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status === 'active')?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(item.status))]):_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.variants",fn:function(ref){
            var item = ref.item;
return _vm._l((item.variants),function(v){return _c('div',{key:v.id},[(v.dbVariantFound)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("error")]),_vm._v(" "+_vm._s(v.title === "Default Title" ? "" : v.title)+" ")],1)})}},{key:"item.productMatch",fn:function(ref){
            var item = ref.item;
return _vm._l((item.dbProduct),function(p){return _c('div',{key:p.id},[_vm._v(" "+_vm._s(p.Name)+" ")])})}},{key:"item.view",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","outlined":"","text":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("edit")]),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","target":"_blank","small":"","href":("https://" + _vm.storeUrl + "/products/" + (item.handle))}},[_vm._v("product"),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("call_made")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","target":"_blank","small":"","href":("https://" + _vm.storeUrl + "/admin/products/" + (item.id))}},[_vm._v("shopify"),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("call_made")])],1)]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }