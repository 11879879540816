<template>
  <v-container grid-list-xl>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0" color="secondary"> test </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  async created() {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {},
};
</script>
