<template>
  <v-container grid-list-lg>
    <v-layout wrap>
      <v-col xs12>
        <v-card>
          <v-card-title primary-title> Profile </v-card-title>
          <v-card-text class="pt-4"> </v-card-text>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}
            <v-btn text @click="snack = false">Close</v-btn>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-layout>
    <v-dialog v-model="dialog" width="500"> </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {},
  mounted: async function () {
    this.getLogo();
  },
};
</script>
