var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v("Soundpaint App Sessions ")]),_c('v-card-text',[(_vm.clientAppSessions.length > 0)?_c('v-alert',{attrs:{"dense":"","border":"left","type":"info"}},[_vm._v(" This customer has "+_vm._s(_vm.uniqueHardwareIds)+" unique hardware IDs. ")]):_vm._e(),(_vm.clientAppSessions.length > 0)?_c('v-alert',{attrs:{"dense":"","border":"left","type":_vm.activeSessions === 3 ? 'error' : 'info'}},[_vm._v(" This customer has "+_vm._s(_vm.activeSessions)+" active sessions (3 is the max). ")]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientAppSessions,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.CreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.CreatedAt,"YYYY/MM/DD hh:mm"))+" ")]}},{key:"item.ExpiredAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.ExpiredAt,"YYYY/MM/DD hh:mm"))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [(item.Expired)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","small":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.expireClientAppSession(item.SessionId)}}},[_vm._v("End")]):_vm._e()]}}])})],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }