<template>
  <v-card elevation="2" class="mb-4">
    <v-card-title
      >FILES
      <v-btn
        color="primary"
        small
        class="ml-4"
        @click="save"
        :loading="saving"
        :disabled="saving"
        >Save Changes</v-btn
      >
    </v-card-title>
    <v-card-text>
      <!-- soundpaint only -->
      <v-alert
        dense
        border="left"
        type="error"
        v-if="conflict"
      >
        There is a <strong>possible discrepency</strong>. Not all file names
        match.
      </v-alert>
      <v-alert
        text
        dense
        color="red"
        icon="mdi-file-remove-outline"
        border="left"
        v-if="selected.length > 0"
      >
        {{ selected.length }} file(s) to be removed.
      </v-alert>
      <v-alert
        text
        dense
        color="green"
        icon="mdi-file-plus-outline"
        border="left"
        v-if="newCount > 0"
      >
        {{ newCount }} file(s) to be added.
      </v-alert>
      <v-autocomplete
        filled
        label="Select File"
        :items="files[this.store]"
        item-text="Key"
        v-model="autocompleteValue"
        autocomplete
        @change="onChangeAutocomplete"
      ></v-autocomplete>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="instrument.files"
        item-key="Key"
        checkbox-color="red"
        show-select
      >
        <template v-slot:item.Key="{ item }">
          {{ item.Key }}
          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            x-small
            v-show="item.New"
          >
            New
          </v-chip>
        </template>
        <template v-slot:item.Size="{ item }">
          {{ prettyBytes(item.Size) }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import fb from "@/main";
import prettyBytes from "pretty-bytes";

export default {
  props: ["files", "instrument"],
  components: {},
  data: () => {
    return {
      store: null,
      saving: false,
      prettyBytes,
      autocompleteValue: null,
      instrumentsInObjectList: [],
      selected: [],
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        {
          text: "Name",
          value: "Key",
          align: "left",
        },
        {
          text: "Size",
          value: "Size",
          align: "left",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
      }
    },
  },
  async created() {
    this.store = this.$route.fullPath.split("/")[1];
  },
  computed: {
    newCount() {
      const selectedMap = this.selected.map((s) => {
        return s.Key;
      });
      const count = this.instrument.files.filter((f) => {
        return f.New && !selectedMap.includes(f.Key);
      }).length;
      return count;
    },
    conflict() {
      if (this.instrument.files.length && this.store === 'soundpaint') {
        const cleanNames = this.instrument.files.map((f) => {
          return f.Key.slice(0, -4);
        });
        return !cleanNames.every((f) => f === cleanNames[0]);
      } else {
        return false;
      }
    },
  },
  methods: {
    onChangeAutocomplete() {
      if (this.autocompleteValue) {
        const file = this.files[this.store].find((f) => {
          return f.Key === this.autocompleteValue;
        });
        const found = this.instrument.files.find((f) => {
          return f.Key === this.autocompleteValue;
        });
        if (found) {
          alert("Sorry, this file was already added.");
          return;
        }
        file.New = true;
        this.instrument.files.push(file);
        this.$nextTick(() => {
          this.autocompleteValue = null;
        });
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully added file to list.";
      }
    },
    async save() {
      const selectedMap = this.selected.map((s) => {
        return s.Key;
      });
      const objectList = this.instrument.files
        .filter((f) => {
          return !selectedMap.includes(f.Key);
        })
        .map((f) => {
          return f.Key;
        });

      if (objectList.length) {
        this.saving = true;
        const saveInstrumentObjectList = fb.functions.httpsCallable(
          "saveInstrumentObjectList"
        );
        const { data: response } = await saveInstrumentObjectList({
          store: this.store,
          id: this.instrument.Id,
          objectList: objectList.join("~"),
        });

        if (response === "success") {
          // clean up table after save
          const instrumentFiles = this.instrument.files;
          instrumentFiles.forEach((f) => {
            delete f["New"];
          });
          this.instrument.files = instrumentFiles.filter((f) => {
            return objectList.includes(f.Key);
          });

          this.selected = [];
          this.saving = false;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Successfully updated instrument files.";
        } else {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error updating instrument files.";
        }
      } else {
        alert("Sorry, you must add at least 1 file.");
      }
    },
  },
};
</script>
