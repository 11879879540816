<template>
  <v-card elevation="2" class="mb-4">
    <v-card-title
      >Soundpaint App Sessions
    </v-card-title>
    <v-card-text>
      <v-alert
        dense
        border="left"
        type="info"
        v-if="clientAppSessions.length > 0"
      >
        This customer has {{uniqueHardwareIds}} unique hardware IDs.
      </v-alert>
      <v-alert
        dense
        border="left"
        :type="activeSessions === 3 ? 'error' : 'info'"
        v-if="clientAppSessions.length > 0"
      >
        This customer has {{activeSessions}} active sessions (3 is the max).
      </v-alert>
      <v-data-table
        :headers="headers"
        :items="clientAppSessions"
        :loading="loading"
      >
        <template v-slot:item.CreatedAt="{ item }">
          {{ item.CreatedAt | moment("YYYY/MM/DD hh:mm") }}
        </template>
        <template v-slot:item.ExpiredAt="{ item }">
          {{ item.ExpiredAt | moment("YYYY/MM/DD hh:mm") }}
        </template>
        <template v-slot:item.options="{ item }">
          <v-btn
            v-if="item.Expired"
            outlined
            text
            small
            class="ml-2"
            @click="expireClientAppSession(item.SessionId)"
            :disabled="saving"
            :loading="saving"
            >End</v-btn
          >
        </template>
      </v-data-table>
    </v-card-text>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import fb from "@/main";

export default {
  components: {},
  data: () => {
    return {
      store: null,
      saving: false,
      loading: false,
      clientAppSessions: [],
      snack: false,
      snackColor: "",
      snackText: "",
      activeSessions: null,
      uniqueHardwareIds: null,
      headers: [
        {
          text: "Session ID",
          value: "SessionId",
          align: "left",
        },
        {
          text: "Hardware ID",
          value: "HardwareId",
          align: "left",
        },
        {
          text: "OS",
          value: "Os",
          align: "left",
        },
        {
          text: "SP App Version",
          value: "AppVersion",
          align: "left",
        },
        {
          text: "Created",
          value: "CreatedAt",
          align: "left",
        },
        {
          text: "Expired",
          value: "ExpiredAt",
          align: "left",
        },
        {
          text: "",
          value: "options",
          align: "right",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
      }
    },
  },
  async created() {
    this.store = this.$route.fullPath.split("/")[1];
    this.getClientAppSessions();
  },
  methods: {
    async getClientAppSessions() {
      this.loading = true;
      const getClientAppSessionsCall = fb.functions.httpsCallable("getClientAppSessions");
      const { data: recordset } = await getClientAppSessionsCall({
        store: this.store,
        clientId: this.$route.params.id
      });
      this.clientAppSessions = recordset;
      this.loading = false;

      const activeSessions = recordset.filter((c) => {return c.ExpiredAt > new Date().toISOString();})
      this.activeSessions = activeSessions.length;

      const uniqueHardwareIds = Array.from(new Set(recordset.map(session => session.HardwareId)));
      this.uniqueHardwareIds = uniqueHardwareIds.length;
    },
    async expireClientAppSession(sessionId) {
      // 3 max active sessions
      if (confirm(`Are you sure you would like to end session ID ${sessionId}?`)) {
        this.saving = true;
        const expireClientAppSessionCall = fb.functions.httpsCallable("expireClientAppSession");
        await expireClientAppSessionCall({
          store: this.store,
          sessionId: sessionId,
          clientId: this.$route.params.id
        });
        this.saving = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = `Successfully deleting session.`;
        this.getClientAppSessions();
      }
    },
  },
};
</script>
