import Vue from "vue";
import Router from "vue-router";
import fb from '@/main';
import store from "@/state/store";

import Home from "@/views/Home.vue";
import Profile from "./views/Profile.vue";
import Login from "./views/Login.vue";
import Products from "@/views/Products.vue";
import Instruments from "@/views/Instruments.vue";
import Instrument from "@/views/Instrument.vue";
import Customers from "@/views/Customers.vue";
import Customer from "@/views/Customer.vue";
import Engine from "@/views/Engine.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      redirect: '/soundpaint/products',
      meta: { requiresAuth: true }
    },
    {
      path: "/soundpaint/products",
      name: "products",
      component: Products,
      meta: { requiresAuth: true }
    },
    {
      path: "/soundpaint/instruments",
      name: "instruments",
      component: Instruments,
      meta: { requiresAuth: true }
    },
    {
      path: "/soundpaint/instruments/:id",
      name: "instrument",
      component: Instrument,
      meta: { requiresAuth: true }
    },
    {
      path: "/soundpaint/customers",
      name: "customers",
      component: Customers,
      meta: { requiresAuth: true }
    },
    {
      path: "/soundpaint/customers/:id",
      name: "customers",
      component: Customer,
      meta: { requiresAuth: true }
    },
    {
      path: "/soundpaint/engine",
      name: "engine",
      component: Engine,
      meta: { requiresAuth: true }
    },
    {
      path: "/8dio/products",
      name: "products",
      component: Products,
      meta: { requiresAuth: true }
    },
    {
      path: "/8dio/instruments",
      name: "instruments",
      component: Instruments,
      meta: { requiresAuth: true }
    },
    {
      path: "/8dio/instruments/:id",
      name: "instrument",
      component: Instrument,
      meta: { requiresAuth: true }
    },
    {
      path: "/8dio/customers",
      name: "customers",
      component: Customers,
      meta: { requiresAuth: true }
    },
    {
      path: "/8dio/customers/:id",
      name: "customers",
      component: Customer,
      meta: { requiresAuth: true }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
  ]
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: '/login'
      });
    } else {
      if(store.getters['user']){
        next()
      } else {
          await store.dispatch('getUser')
          next();
      }
    }
  } else {
    // Proceed to route
    next()
  }
});
export default router;
