<template>
  <v-card elevation="2" class="mb-4">
    <v-card-title
      >BUNDLE
      <v-btn
      color="primary"
      small
      class="ml-4"
      @click="save"
      :loading="saving"
      :disabled="saving"
      >Save Changes</v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-alert
        text
        dense
        color="red"
        icon="mdi-file-remove-outline"
        border="left"
        v-if="selected.length > 0"
      >
        {{ selected.length }} instrument(s) to be removed.
      </v-alert>
      <v-alert
        text
        dense
        color="green"
        icon="mdi-file-plus-outline"
        border="left"
        v-if="newCount > 0"
      >
        {{ newCount }} instrument(s) to be added.
      </v-alert>
      <v-autocomplete
        filled
        label="Select Instrument"
        :items="instruments"
        item-text="Name"
        v-model="autocompleteValue"
        autocomplete
        @change="onChangeAutocomplete"
      ></v-autocomplete>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="instrumentsInBundle"
        :loading="loading"
        item-key="Name"
        checkbox-color="red"
        show-select
      >
        <template v-slot:item.Id="{ item }">
          <a :href="`/${store}/instruments/${item.Id}`" target="_blank">
            {{item.Id}}
          </a>
        </template>
        <template v-slot:item.Name="{ item }">
          {{item.Name}}
          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            x-small
            v-show="item.New"
          >
            New
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import fb from "@/main";

export default {
  props: ['instrument'],
  components: {
  },
  data: () => {
    return {
      store: null,
      loading: false,
      saving: false,
      instruments: [],
      autocompleteValue: null,
      instrumentsInBundle: [],
      selected: [],
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        {
          text: "ID",
          value: "Id",
          align: "left",
        },
        {
          text: "Name",
          value: "Name",
          align: "left",
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
        this.getInstrument();
      }
    },
  },
  async created() {
    this.store = this.$route.fullPath.split("/")[1];
    this.getInstruments();
    this.getInstrumentsInBundle();
  },
  computed: {
    newCount() {
      const selectedMap = this.selected.map((s) => {return s.Name});
      const count = this.instrumentsInBundle.filter((f) => {
        return f.New && !selectedMap.includes(f.Name)
      }).length
      return count;
    }
  },
  methods: {
    async getInstruments() {
      const instrumentsCall = fb.functions.httpsCallable("getInstruments");
      const { data: recordset } = await instrumentsCall({
        store: this.store
      });
      this.instruments = recordset;
    },
    async getInstrumentsInBundle() {
      this.loading = true;
      const instrumentsInBundleCall = fb.functions.httpsCallable("getInstrumentsInBundle");
      const { data: recordset } = await instrumentsInBundleCall({
        store: this.store,
        id: this.$route.params.id,
      });
      this.loading = false;
      console.log(recordset, 'recordset')
      this.instrumentsInBundle = recordset;
    },
    onChangeAutocomplete() {
      if (this.autocompleteValue) {
        const instrument = this.instruments.find((f) => {return f.Name === this.autocompleteValue});
        const found = this.instrumentsInBundle.find((f) => {return f.Name === this.autocompleteValue});
        if (found) {
          alert("Sorry, this instrument was already added.")
          return;
        }
        instrument.New = true;
        this.instrumentsInBundle.push(instrument)
        this.$nextTick(() => {
            this.autocompleteValue = null
        })
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully added instrument to list.";
      }
    },
    async save() {
      const instrumentNamesForDelete = this.selected.map((s) => {return s.Name});
      const bundleDeletions = this.instrumentsInBundle.filter((f) => {
        return instrumentNamesForDelete.includes(f.Name) && !f.New
      });
      const bundleInsertions = this.instrumentsInBundle.filter((f) => {
        return !instrumentNamesForDelete.includes(f.Name) && f.New
      });
      const isBundle = (this.instrumentsInBundle.filter((f) => {
        return !instrumentNamesForDelete.includes(f.Name)
      })).length > 0;
      const objectList = (this.instrumentsInBundle.filter((f) => {
        return !instrumentNamesForDelete.includes(f.Name)
      })).map((f) => {return f.ObjectList});

      this.saving = true;
      const saveInstrumentBundle = fb.functions.httpsCallable("saveInstrumentBundle");
      const { data: response } = await saveInstrumentBundle({
        store: this.store,
        id: this.$route.params.id,
        instrumentIdsForInsert: bundleInsertions.map((f) => {return f.Id}),
        instrumentIdsForDelete: bundleDeletions.map((f) => {return f.Id}),
        isBundle: isBundle,
        objectList: objectList.join("~")
      });

      if (response === 'success') {
        // clean up table after save
        const instrumentsInBundle = this.instrumentsInBundle;
        instrumentsInBundle.forEach((f) => {delete f['New']});
        this.instrumentsInBundle = instrumentsInBundle.filter((f) => {return !instrumentNamesForDelete.includes(f.Name)});
        this.instrument.IsBundle = isBundle;
        this.selected = [];
        this.saving = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully updated bundle.";
      }
      else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error updating bundle."
      }
    },
  }
};
</script>