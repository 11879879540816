<template>
    <div>
        <p class="pl-1">
            <v-icon class="mr-2">library_music</v-icon>Need to add an instrument to {{clientIds.length > 1 ? `these ${clientIds.length} customer accounts` : "this customer's account"}}?
            <v-btn
                rounded
                outlined
                text
                x-small
                class="ml-2"
                @click="showDialog"
                >Click here</v-btn
            >
        </p>
        <v-dialog v-model="dialog.show" persistent :overlay="false" max-width="600px">
            <v-card>
            <v-card-title class="title secondary mb-4">Add Instrument</v-card-title>
            <v-card-text>
                <p>
                Please select an instrument to add it to the account(s).
                </p>
                <v-autocomplete
                v-model="dialog.instrument"
                :items="instrumentsList"
                dense
                filled
                label="Instruments"
                item-text="Name"
                item-value="Id"
                return-object
                @change="dialog.createShopifyOrder = false;"
                ></v-autocomplete>

                <p v-if="dialog.instrument">
                Instrument ID: {{dialog.instrument.Id}}
                <br/>
                Bundle: {{dialog.instrument.IsBundle ? 'Yes' : 'No'}}
                <br/>
                Shopify Product: {{dialog.instrument.ShopifyProductId ? 'Yes' : 'No'}}
                <span v-if="dialog.instrument.ShopifyProductId">
                    <br/>
                    Shopify Product ID:
                    <a :href="`https://${storeUrl}/admin/products/${dialog.instrument.ShopifyProductId}`" target="_blank">
                    {{dialog.instrument.ShopifyProductId}}
                    </a>
                    <br/>
                    Shopify Variant ID: {{dialog.instrument.ShopifyVariantId || 'N/A'}}
                </span>
                </p>
                <p v-if="dialog.instrument && dialog.instrument.IsBundle">
                Adding this bundle to {{customerName}}'s account will auto-add all child instruments too.
                </p>
                <v-checkbox
                v-show="dialog.instrument && dialog.instrument.ShopifyVariantId"
                v-model="dialog.createShopifyOrder"
                label="Create Shopify Order"
                hide-details
                ></v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="primary"
                text
                @click="
                    dialog.show = false;
                "
                >Cancel</v-btn
                >
                <v-btn color="primary" @click="addCustomerInstrument()" :loading="saving" :disabled="saving">
                Add
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>


<script>
    import fb from "@/main";
    
    export default {
      props: ['clientIds'],
      components: {
      },
      data: () => {
        return {
          loading: false,
          saving: false,
          instruments: [],
          instrumentsInBundle: [],
          instrumentsList: [],
          dialog: {
            show: false,
            instrument: null,
            createShopifyOrder: false
          },
          snack: false,
          snackColor: "",
          snackText: "",
        }
      },
      watch: {
            $route(to, from) {
            console.log(to, from);
            if (to.fullPath !== from.fullPath) {
                this.store = to.fullPath.split("/")[1];
                this.getInstrument();
            }
        },
      },
      computed: {
        storeUrl() {
            if (this.store === "soundpaint") {
                return "soundpaint.myshopify.com";
            } else {
                return "8dio-com.myshopify.com";
            }
        }
      },
      async created() {
          this.store = this.$route.fullPath.split("/")[1];
          this.getInstruments();
      },
      methods: {
        async getInstruments() {
            console.log("--------", "getting products for", this.store);
            const instrumentsCall = fb.functions.httpsCallable("getInstruments");
            const { data: recordset } = await instrumentsCall({
                store: this.store,
            });
            console.log(this.store, "instruments", recordset.length);
            this.instrumentsList = recordset;
        },
        async addCustomerInstrument() {
            if (!this.dialog.instrument || !this.dialog.instrument.Id || !this.clientIds || !this.clientIds.length) {
                return
            }
            if (this.clientIds.length > 50) {
                alert("Sorry, you can only process up to 50 customers at a time.");
                return
            }
            const failedClientIds = [];
            this.saving = true;
            for (const clientId of this.clientIds) {
                try {
                    const addDownloaderRecordCall = fb.functions.httpsCallable("addDownloaderRecord");
                    await addDownloaderRecordCall({
                        store: this.store,
                        instrument: this.dialog.instrument,
                        id: clientId,
                        createShopifyOrder: this.dialog.createShopifyOrder
                    });
                } catch (e) {
                    failedClientIds.push(clientId);
                }
            }

            if (failedClientIds.length) {
                alert(`There were errors adding the instrument to ${failedClientIds.length} client IDs: ${failedClientIds.join(', ')}`)
            }
            this.dialog.show = false;
            this.saving = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = `Successfully added instrument.`;
            this.$emit("instrumentAdded");
        },
        showDialog() {
            this.dialog = {
                show: true,
                instrument: null,
                createShopifyOrder: false
            }
        }
      }
    }
</script>