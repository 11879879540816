import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/state/store";
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import firebaseConfig from './firebaseConfig'
Vue.use(require('vue-moment'));
firebase.initializeApp(firebaseConfig)

if (location.hostname === "localhost") {
  /* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
  console.log('------------------')
  // console.info('USING LOCAL DEV')
  console.log('%c DEV MODE! ', 'background: #183757; color: #ffff');
  console.log('------------------')
  // firebase.firestore().settings({
  //   host: "localhost:9999",
  //   ssl: false,
  //   experimentalForceLongPolling: true // fix for weird bug on local where docs dont load
  // });
  firebase.functions().useEmulator("localhost", 5001);
}

const fb = {
  auth: firebase.auth(),
  db: firebase.firestore(),
  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  functions: firebase.functions(),
  storage: firebase.storage()
}

Vue.filter('toCurrency', function (value) {
  const numberValue = parseFloat(value)
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
  });
  return formatter.format(numberValue);
});


Vue.config.productionTip = false

let app;
fb.auth.onAuthStateChanged(function() {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});

export default fb;