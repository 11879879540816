import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import fb from "@/main";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    instrumentFiles: {
      "8dio": [],
      soundpaint: [],
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    instrumentFiles: (state) => {
      return state.instrumentFiles;
    },
  },
  mutations: {
    cacheUser(state, user) {
      state.user = user;
    },
    cacheInstrumentFiles(state, instrumentFiles) {
      state.instrumentFiles = Object.assign(
        state.instrumentFiles,
        instrumentFiles
      );
    },
  },
  actions: {
    signout: async function () {
      try {
        await fb.auth.signOut();
        router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    getUser: async function ({ commit }) {
      try {
        const { uid } = await fb.auth.currentUser;
        const userRes = await fb.db.collection("users").doc(uid).get();
        const user = userRes.data();
        commit("cacheUser", { ...user });
      } catch (error) {
        console.log("error in getUser", error);
        alert(error);
      }
    },
    getInstrumentFiles: async function ({ commit }, store) {
      try {
        console.log("in store", store);
        const getFiles = fb.functions.httpsCallable("getFiles");
        const { data } = await getFiles({
          store,
        });

        const item = {};
        item[store] = data;
        commit("cacheInstrumentFiles", item);
      } catch (error) {
        console.log(error);
      }
    },
  },
});

export default store;
