<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog.show"
      persistent
      :overlay="false"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="title secondary mb-4"
          >{{ getOsFamilyName(dialog.record.OsFamily) }} -
          {{ dialog.action }} Record</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="dialog.record.AppVersion"
            filled
            label="App Version"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="dialog.record.OsVersionMin"
            filled
            label="OS Min Version"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="dialog.record.OsVersionMax"
            filled
            label="OS Max Version"
            type="text"
          ></v-text-field>
          <v-text-field
            v-model="dialog.record.Url"
            filled
            label="URL"
            type="text"
          ></v-text-field>
          <v-textarea
            v-model="dialog.record.Info"
            filled
            label="Info"
            type="text"
            style="white-space: pre"
          ></v-textarea>
          <v-text-field
            v-model="dialog.record.Beta"
            filled
            label="Beta"
            type="text"
          ></v-text-field>
          <v-checkbox
            v-model="dialog.record.Private"
            label="Private"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            @click="save()"
            :loading="dialog.saving"
            :disabled="dialog.saving"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <h1 class="headline">
          {{ $route.path.split("/")[1].toUpperCase() }} Engine
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="10"
          :loading="loading"
          sort-by="OsFamily"
        >
          <template v-slot:item.OsFamily="{ item }">
            <span>{{ getOsFamilyName(item.OsFamily) }}</span>
          </template>
          <template v-slot:item.Info="{ item }">
            <span>{{ truncateString(item.Info, 30) }}</span>
          </template>
          <template v-slot:item.Url="{ item }">
            <span>{{ truncateString(item.Url, 22) }}</span>
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn small outlined text @click="openDialog(item, 'Edit')"
              >edit</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
export default {
  name: "Engine",
  data() {
    return {
      operatingSystems: [
        { name: "Windows", value: 0 },
        { name: "Mac", value: 1 },
        { name: "PUBLIC Win", value: 2 },
        { name: "PUBLIC Mac", value: 3 },
        { name: "DESIGNER Win", value: 4 },
        { name: "DESIGNER Mac", value: 5 },
      ],
      loading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      dialog: {
        action: "",
        saving: false,
        show: false,
        record: {},
      },
      records: [],
      headers: [
        // {
        //   text: "ID",
        //   value: "Id",
        //   align: "left",
        // },
        {
          text: "App",
          value: "AppVersion",
          align: "left",
        },
        {
          text: "OS",
          value: "OsFamily",
          align: "left",
        },
        {
          text: "OS Min",
          value: "OsVersionMin",
          align: "left",
        },
        {
          text: "OS Max",
          value: "OsVersionMax",
          align: "left",
        },
        {
          text: "Info",
          value: "Info",
          align: "left",
        },
        {
          text: "URL",
          value: "Url",
          align: "left",
        },
        {
          text: "Beta",
          value: "Beta",
          align: "left",
        },
        {
          text: "Private",
          value: "Private",
          align: "left",
        },
        {
          text: "",
          align: "end",
          value: "view",
        },
      ],
    };
  },
  async created() {
    try {
      this.store = this.$route.fullPath.split("/")[1];
      this.loading = true;
      await this.getClientAppVersions();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({ user: "user" }),
    storeUrl() {
      if (this.store === "soundpaint") {
        return "soundpaint.myshopify.com";
      } else {
        return "8dio-com.myshopify.com";
      }
    },
  },
  methods: {
    getOsFamilyName(val) {
      switch (val) {
        case 0:
          return "Win";
        case 1:
          return "Mac";
        case 2:
          return "PUBLIC Win";
        case 3:
          return "PUBLIC Mac";
        case 4:
          return "DESIGNER Win";
        case 5:
          return "DESIGNER Mac";
        default:
          break;
      }
    },
    truncateString(str, num) {
      if (str && str.length > num) {
        let strEnd = "";
        if (str.length > num * 3) {
          strEnd = str.substring(str.length - Math.ceil(num / 2));
        }
        return str.slice(0, num) + "..." + strEnd;
      } else {
        return str;
      }
    },
    async getClientAppVersions() {
      const getClientAppVersionCall = fb.functions.httpsCallable(
        "getClientAppVersions"
      );
      const { data: recordset } = await getClientAppVersionCall({
        store: this.store,
      });
      if (recordset.length) {
        this.records = recordset;
      } else {
        this.records = [];
      }
    },
    async openDialog(item, action) {
      this.dialog.action = action;
      this.dialog.record = item;
      this.dialog.show = true;
    },
    async save() {
      const action = this.dialog.action.toLowerCase();
      try {
        if (
          !(this.dialog.record.OsFamily > -1) ||
          !this.dialog.record.AppVersion ||
          !this.dialog.record.OsVersionMin ||
          !this.dialog.record.Url
        ) {
          return;
        }
        this.dialog.saving = true;
        const saveClientAppVersionCall = fb.functions.httpsCallable(
          "saveClientAppVersion"
        );
        await saveClientAppVersionCall({
          store: this.store,
          action: action,
          id: this.dialog.record.Id,
          appVersion: this.dialog.record.AppVersion,
          url: this.dialog.record.Url,
          osFamily: this.dialog.record.OsFamily,
          osVersionMin: this.dialog.record.OsVersionMin,
          osVersionMax: this.dialog.record.OsVersionMax,
          info: this.dialog.record.Info,
          beta: this.dialog.record.Beta,
          private: this.dialog.record.Private,
        });

        await this.getClientAppVersions();

        this.dialog.show = false;
        this.dialog.saving = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = `Successfully ${action}ed record`;
      } catch (error) {
        this.dialog.saving = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = `Error ${action}ing record`;
      }
    },
  },
};
</script>
